import cloneDeep from 'lodash.clonedeep';
import { createStore } from 'vuex';
import * as Sentry from '@sentry/browser';

import { objectsEqual } from '@/lib/compare';
import { EventLog } from '@/lib/event-log';
import {
  ANALYTICS_CAPABILITY,
  INTELLIGENCE_CAPABILITY,
} from '@/lib/identity-methods';
import router from '@/router/index';
import IdentityService from '@/services/identity';
import UsersService from '@/services/users';
import uploadFiles from './modules/upload-files';
import filters from './modules/filters';
import menus from './modules/menus';
import notifications from './modules/notifications';
import reports from './modules/reports';
import requests from './modules/requests';
import sources from './modules/sources';
import tenant from './modules/tenant';
import user from './modules/user';
import users from './modules/users';

// Vue.use(Vuex);

// export default new Vuex.Store({
const store = createStore({
  modules: {
    uploadFiles,
    filters,
    menus,
    notifications,
    reports,
    requests,
    sources,
    tenant,
    user,
    users,
  },
  state: {
    app: null,
    loginComplete: false,
    tenantSwitchComplete: true,
    config: {},
  },
  mutations: {
    SET_APP(state, app) {
      state.app = app;
    },
    SET_CONFIG(state, config) {
      state.config = config;
    },
    SET_LOGIN_COMPLETE(state, status) {
      state.loginComplete = status;
    },
    SET_TENANT_SWITCH_COMPLETE(state, status) {
      state.tenantSwitchComplete = status;
    },
  },
  actions: {
    login({ state, dispatch, rootGetters }, fromClaims) {
      return new Promise((resolve, reject) => {
        let request = IdentityService.postLogin;
        if (fromClaims) {
          request = rootGetters.claimsToken ? IdentityService.getUserClaims : IdentityService.getClaimsToken;
        }
        request().then((json) => {
          if (json.claims_token) {
            dispatch('setClaimsToken', { claimsToken: json.claims_token });
          }

          if (!json.tenants || json.tenants.length === 0) {
            throw new Error('No tenants associated with your user.');
          }
          const query = { ...router.currentRoute.value.query };
          const userInfo = json.user;
          const { tenants, capabilities } = json;
          let currentTenant = tenants.find((t) => t.id === json.tenant_id);
          const tenantQuery = /[&?]tenant=\w*&*/;
          if (query.redirect && tenantQuery.test(query.redirect)) {
            const tenantQueryParam = query.redirect.split('tenant=')[1].split('&')[0];
            const queryTenant = tenants.find((t) => t.name === tenantQueryParam);
            currentTenant = queryTenant || currentTenant;
            query.redirect = query.redirect.replace(tenantQuery, '');
            if (!objectsEqual(router.currentRoute.value.query, query)) {
              router.push({ query });
            }
          }
          const defaultTenant = tenants.find((t) => t.is_default);
          const options = {
            tenant: currentTenant,
            initialLoad: true,
          };
          const identityUser = state.app.config.globalProperties.$firebaseAuth.currentUser;
          let multiFactor;
          if (identityUser && identityUser.multiFactor &&
            identityUser.multiFactor.enrolledFactors.length > 0) {
            [multiFactor] = identityUser.multiFactor.enrolledFactors;
            if (!userInfo.mfa_enabled) {
              UsersService.postUserMfa(userInfo.id, { mfa_enabled: true });
              userInfo.mfa_enabled = true;
            }
          } else if (userInfo.mfa_enabled) {
            UsersService.postUserMfa(userInfo.id, { mfa_enabled: false });
            userInfo.mfa_enabled = false;
          }
          dispatch('setUser', userInfo);
          dispatch('setMultiFactor', multiFactor);
          dispatch('setDefaultTenant', defaultTenant);
          dispatch('setTenants', tenants);
          dispatch('setCapabilities', capabilities);
          dispatch('updateTenant', options);
          if ('track' in query) {
            const body = {
              token: query.track,
            };
            UsersService.postTrackTokenRead(userInfo.id, body).then((result) => {
              if (result.error) {
                const errorData = new EventLog({
                  event: 'debug.fail_set_track_id',
                  error: result.error,
                });
                UsersService.postTrackingLog(errorData);
              } else {
                const trackId = result.track_id;
                dispatch('setDebugUser', true);
                sessionStorage.setItem('track_id', trackId);
                Sentry.setTag('track_id', trackId);
                const loggingData = new EventLog({
                  event: 'debug.set_track_id',
                  trackId,
                });
                UsersService.postTrackingLog(loggingData);
              }
            }).catch((error) => {
              const errorData = new EventLog({
                event: 'debug.fail_set_track_id',
                error: error.message,
              });
              UsersService.postTrackingLog(errorData);
            }).finally(() => {
              if (!objectsEqual(router.currentRoute.value.query, query)) {
                router.push({ query });
              }
            });
          }
          if (!fromClaims) {
            const successLog = new EventLog({
              event: 'account.log_in',
              email: userInfo.email,
            });
            UsersService.postTrackingLog(successLog);
          }
          dispatch('setSessionTimer');
          resolve();
        }).catch((error) => {
          reject(error);
        }).finally(() => {
          dispatch('setLoginComplete', true);
        });
      });
    },
    logout({ state, dispatch, rootGetters }) {
      return new Promise((resolve, reject) => {
        const successLog = new EventLog({
          event: 'account.log_out',
          email: rootGetters.user ? rootGetters.user.email : null,
        });
        UsersService.postTrackingLog(successLog);
        dispatch('resetRequests');
        dispatch('resetUser');
        dispatch('resetTenant');
        dispatch('resetFilters');
        dispatch('resetReports');
        dispatch('resetUsers');
        dispatch('resetSources');
        dispatch('resetMenus');
        dispatch('setLoginComplete', true);
        dispatch('setTenantSwitchComplete', true);
        if (SENTRY_ENABLED) {
          Sentry.configureScope((scope) => scope.clear());
        }
        state.app.config.globalProperties.$firebaseAuth.signOut().then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      });
    },
    setLoginComplete({ commit }, status) {
      commit('SET_LOGIN_COMPLETE', status);
    },
    setTenantSwitchComplete({ commit }, status) {
      commit('SET_TENANT_SWITCH_COMPLETE', status);
    },
    setConfig({ commit }, config) {
      commit('SET_CONFIG', config);
    },
    setTenantData({ dispatch, rootGetters }, options) {
      return new Promise((resolve, reject) => {
        const initialLoad = options ? options.initialLoad : false;
        const tenantInfo = options && options.tenant ? options.tenant : rootGetters.tenant;
        const userCapabilities = rootGetters.capabilities;
        dispatch('resetUsers');
        dispatch('setTenant', tenantInfo);
        if (userCapabilities.includes(ANALYTICS_CAPABILITY)) {
          dispatch('setFiltersLoaded', false);
          dispatch('setUserFilterSetsLoaded', false);
          dispatch('setFilters', initialLoad).then(() => { dispatch('setUserFilterSets', initialLoad); });
        }
        if (userCapabilities.includes(INTELLIGENCE_CAPABILITY)) {
          dispatch('setReportsState', 'loading');
          dispatch('setReports', tenantInfo);
        }
        dispatch('setMenus').then(() => {
          const allMenus = rootGetters.menus.concat(rootGetters.navbarItems);
          const routerPath = cloneDeep(router.currentRoute.value.path);
          const paths = routerPath.split('/');
          const currentPath = paths[1];
          if (currentPath && tenantInfo.allowed_menus && !tenantInfo.allowed_menus.includes(currentPath)) {
            const allowedMenu = allMenus.find((menu) => menu.url === tenantInfo.allowed_menus[0]) || allMenus[0];
            const path = `/${allowedMenu.url}`;
            if (routerPath !== path) {
              router.push({ path });
            }
          }
          if (!initialLoad) {
            const defaultMenu = allMenus[0];
            const defaultSub = defaultMenu.subs && defaultMenu.subs.length > 0 ? defaultMenu.subs[0] : null;
            const defaultSubSection = defaultSub && 'sections' in defaultSub ? defaultSub.sections[0] : null;
            const currentMenu = allMenus.find((menu) => menu.url === currentPath);
            let defaultPath;
            if (defaultSubSection) {
              defaultPath = `/${defaultMenu.url}/${defaultSubSection.url}`;
            } else if (defaultSub) {
              defaultPath = `/${defaultMenu.url}/${defaultSub.url}`;
            } else {
              defaultPath = `/${defaultMenu.url}`;
            }
            if (!currentPath) {
              router.push({ path: defaultPath });
            } else if (paths.length > 2) {
              const subPath = paths[2];
              if (!currentMenu) {
                router.push({ path: defaultPath });
              } else if (currentMenu.subs) {
                const urls = [];
                currentMenu.subs.forEach((sub) => {
                  if ('sections' in sub) {
                    sub.sections.forEach((section) => urls.push(section.url));
                  } else if ('url' in sub) {
                    urls.push(sub.url);
                  }
                });
                if (!urls.includes(subPath)) {
                  const path = `/${currentMenu.url}/${urls[0]}`;
                  router.push({ path });
                }
              }
            }
          }
          dispatch('setCurrentPath');
          resolve();
        });
      });
    },
    updateTenant({ dispatch, rootGetters }, options) {
      return new Promise((resolve, reject) => {
        dispatch('setTenantSwitchComplete', false);
        const withClaims = options ? options.withClaims : false;
        const resetRequests = options ? options.resetRequests : false;
        const tenantInfo = options && options.tenant ? options.tenant : rootGetters.tenant;
        if (resetRequests) {
          dispatch('resetRequests');
        }
        if (withClaims) {
          dispatch('setClaimsToken', { tenantId: tenantInfo.id }).then(() => {
            dispatch('setTenantData', options).then(() => {
              dispatch('setTenantSwitchComplete', true);
              resolve();
            });
          });
        } else {
          dispatch('setTenantData', options).then(() => {
            dispatch('setTenantSwitchComplete', true);
            resolve();
          });
        }
      });
    },
  },
  getters: {
    app: (state) => state.app,
    loginComplete: (state) => state.loginComplete,
    tenantSwitchComplete: (state) => state.tenantSwitchComplete,
    config: (state) => state.config,
  },
  strict: VUE_STORE_STRICT,
});

export default store;
