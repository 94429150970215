<template>
  <div class="status-notification-container">
    <div
      class="status-top">
      <v-icon
        size="25"
        :icon="icons[level]"/>
      <div
        class="status-notification"
        :class="expandMessage ? 'expand-status' : ''">
        <span class="status-title">{{ title }}</span> <br>
        <span>{{ message }}</span>
      </div>
    </div>
    <div
      class="status-expand-btn"
      @click="expandMessage = !expandMessage">
      <v-icon
        class="status-expand-btn-icon"
        size="28"
        :icon="expandMessage ? 'mdi-menu-up' : 'mdi-menu-down'"/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotificationCard',
  props: {
    title: {
      type: String,
      required: true,
    },    
    message: {
      type: String,
      required: true,
    },
    level: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        warn: 'mdi-alert',
        info: 'mdi-information',
      },
      expandMessage: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/main.scss';
.status-notification-container {
  margin-top: 1rem !important;
  margin: auto;
  align-items: start !important;
  display: flex;
  max-width: 600px;
  border-radius: 6px;
  color: black;
  font-weight: 600;
  background-color: lighten($bainbridge-gold,30);
  opacity: .7;
}

.status-expand-btn {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  border-top: 1px lighten($bainbridge-gold,15) solid;
  cursor: pointer;
}

.status-expand-btn-icon {
  margin: auto;
}

.status-title {
  font-weight: 600;
}
.status-top {
  display: flex;
  flex-direction: row !important;
  align-items: start !important;
  margin-top: 12px;
  margin-left: 12px;
  margin-bottom: 12px;
  gap: 20px;
}
.status-notification {
  max-height: 50px;
  max-width: 500px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.expand-status {
    white-space: normal;
    max-height: 500px;
  }
}
</style>
