<template>
  <div class="alerts-overlay">
    <div class="alerts-menu">
      <div>
        <v-icon
          size="28"
          :icon="icon"/>
      </div>
      <div>
        <h3> {{ title }} </h3>
        <br>
        <span> {{ message }} </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertsCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/main.scss';
@import '@/sass/icons.scss'; // Icons are loaded separately due to relative namespacing.

.alerts-overlay {
  position: absolute;
  display: flex;
  top: 52px;
  bottom: 0px;
  left: calc(100% - 500px);
  right: 24px;
  flex-direction: column;
  align-items: center;
  color: #000000;
}

.alerts-menu {
  display: flex;
  gap: 12px;
  width: 100%;
  padding: 18px;
  background-color: lighten($bainbridge-gold, 25);
  opacity: .9;
  z-index: 3;
  border-radius: 6px;
}
</style>
