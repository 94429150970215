import * as Sentry from '@sentry/vue';
import { initializeApp } from 'firebase/app';
import { getAuth, onIdTokenChanged } from 'firebase/auth';
import '@mdi/font/css/materialdesignicons.css';
import { createApp } from 'vue';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { v1 as uuidv1 } from 'uuid';

import detectBrowser from 'vue-detect-browser';
import { EventLog } from '@/lib/event-log';
import { INTERNAL_IDENTITY_METHOD } from '@/lib/identity-methods';
import theme from './lib/color-theme';
import './sass/icons.scss'; // Icons are loaded separately due to relative namespacing.
import './sass/main.scss';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import Services from './services/index';
import './directives/focus';

import './lib/remove-polyfill';
import './lib/url-polyfill';

let app;
let appMounted = false;

const queryParams = new URLSearchParams(window.location.search);
const TRACK_ENABLED = queryParams.has('track');
const DEBUG_ENABLED = VUE_DEBUG_ENABLED || TRACK_ENABLED;

const vuetify = createVuetify({
  theme: theme.theme,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

const initializeVue = (message) => {
  if (!app) {
    app = createApp(App);

    if (DEBUG_ENABLED) {
      /* eslint-disable no-console */
      console.info('debug: enabled (t=%s, v=%s)', TRACK_ENABLED, VUE_DEBUG_ENABLED);
      /* eslint-enable no-console */
      app.config.devtools = true;
      app.config.debug = true;
      app.config.silent = false;
    } else {
      /* eslint-disable no-console */
      console.info('debug: disabled (t=%s, v=%s)', TRACK_ENABLED, VUE_DEBUG_ENABLED);
      /* eslint-enable no-console */
      app.config.devtools = false;
      app.config.debug = false;
      app.config.silent = true;
    }

    app.use(detectBrowser);
    app.use(vuetify);
    app.use(Services);
    app.use(store);

    store.commit('SET_APP', app);
    Object.defineProperty(app.config.globalProperties, '$notify', {
      get() {
        return (msg) => {
          app.config.globalProperties.$store.dispatch('createSnack', msg);
        };
      },
    });
    // app.mount('#app');
    if (DEBUG_ENABLED) {
      window.App = app;

      /* eslint no-console: ["error", { allow: ["error"] }] */
      console.error('cf. window.App');
    }
  }
  if (message) {
    app.config.globalProperties.$notify(message);
  }

  // Set session ID to track user's current app session
  sessionStorage.setItem('track_id', uuidv1());

  Services.config.getConfig().then((config) => {
    if (config.sentry) {
      Sentry.init({
        app,
        dsn: config.sentry.dsn,
        environment: config.sentry.environment,
        integrations: [
          Sentry.browserTracingIntegration({ router }),
        ],
      });
      Sentry.setTag('track_id', sessionStorage.getItem('track_id'));
    }

    store.dispatch('setConfig', config);
    // Initialize Google Identity Platform
    const firebaseApp = initializeApp(config.identity);
    Object.defineProperty(app.config.globalProperties, '$firebaseApp', {
      get() {
        return firebaseApp;
      },
    });
    const auth = getAuth(app.config.globalProperties.$firebaseApp);
    Object.defineProperty(app.config.globalProperties, '$firebaseAuth', {
      get() {
        return auth;
      },
    });

    // Router required the firbaseAuth to be configured
    // So this is placed below that configuration otherwise
    // it will thrown an error
    // Check if this has been ran already
    // If not SSO it will attempt to run a second time and give
    // a blank page
    auth.onAuthStateChanged(() => {
      if (!appMounted) {
        app.use(router);
        router.isReady().then(() => {
          appMounted = true;
          app.mount('#app');
        });
      }
    });

    onIdTokenChanged(auth, (userInfo) => {
      if (userInfo) {
        userInfo.getIdToken().then((idToken) => {
          store.dispatch('setAccessToken', idToken);
          if (!localStorage.getItem('claimsToken') && !store.getters.user) {
            const ssoUser = userInfo.providerData && userInfo.providerData.length > 0 &&
              userInfo.providerData[0].providerId !== INTERNAL_IDENTITY_METHOD;
            if (ssoUser) {
              let accountMatch = true;
              const identityEmail = userInfo.email;
              const signinEmail = localStorage.getItem('signinEmail');
              if (signinEmail) {
                accountMatch = identityEmail && signinEmail.toLowerCase() === identityEmail.toLowerCase();
                if (!accountMatch) {
                  store.dispatch('logout').then(() => {
                    app.config.globalProperties.$notify('Account does not match the email address initially provided.');
                    const failLog = new EventLog({
                      event: 'account.fail_login',
                      email: signinEmail,
                      error: 'account_mismatch',
                    });
                    Services.users.postTrackingLog(failLog);
                  });
                }
              }
              if (!signinEmail || accountMatch) {
                store.dispatch('login').catch((error) => {
                  store.dispatch('logout').then(() => {
                    app.config.globalProperties.$notify('Login failed.');
                    const failLog = new EventLog({
                      event: 'account.fail_login',
                      email: userInfo.email,
                      error: error.message,
                    });
                    Services.users.postTrackingLog(failLog);
                  });
                });
              }
            } else {
              store.dispatch('setLoginComplete', true);
            }
          } else if (!store.getters.user) {
            store.dispatch('setLoginComplete', true);
          }
        });
      } else {
        store.dispatch('setLoginComplete', true);
      }
    });
  });
};

initializeVue();
