<template>
  <v-container class="pt-12 sso-config">
    <v-card class="text-left">
      <v-card-title class="bg-secondary">
        <span class="text-white">
          Single Sign-On Configuration
        </span>
      </v-card-title>
      <v-card-text
        v-if="!identityConfigLoaded"
        class="d-flex justify-center my-4">
        <v-progress-circular
          indeterminate
          class="text-primary"/>
      </v-card-text>
      <v-card-text v-else>
        <span
          v-if="identityConfigExists"
          class="d-flex justify-end">
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                id="delete-button"
                :disabled="loading"
                class="mx-0"
                color="primary"
                variant="text"
                icon
                @click="showConfirmationModal(MODAL_REASONS.DELETE_CONFIG)">
                <v-icon icon="mdi-close"/>
              </v-btn>
            </template>
            <span>Click to remove SSO configuration.</span>
          </v-tooltip>
        </span>
        <div
          v-if="!identityConfigExists"
          class="mt-4 pb-4">
          Set up single sign-on (SSO) for your organization's users.
        </div>
        <div
          class="pb-2">
          <v-form
            ref="ssoForm"
            v-model="valid"
            lazy-validation>
            <v-text-field
              v-model="localEntityId"
              :disabled="(!editingConfig && identityConfigExists) || loading"
              name="service-id"
              label="Federation Service ID*"
              required
              variant="underlined"
              validate-on="blur"/>
            <v-text-field
              v-model="localSsoUrl"
              :rules="urlRules"
              :disabled="(!editingConfig && identityConfigExists) || loading"
              name="login-url"
              label="Login URL*"
              required
              variant="underlined"
              validate-on="blur"/>
            <v-textarea
              v-model="localSamlCert"
              :rules="certRules"
              :disabled="(!editingConfig && identityConfigExists) || loading"
              name="saml-cert"
              label="SAML Certificate*"
              required
              variant="outlined"
              validate-on="blur"/>
            <v-switch
              v-model="toggleRoleMapping"
              :disabled="(!editingConfig && identityConfigExists) || loading"
              label="Map Security Groups to Roles"
              color="primary"
              class="mb-4"
              variant="underlined"
              hide-details/>
            <div
              :class="{
                'mb-2': toggleRoleMapping,
                'mb-6': !toggleRoleMapping,
              }"
              class="mx-4">
              This option should only be selected if you are not configuring mapping within your Active
              Directory instance. It is recommended to configure mapping there so that user access is within
              your organization’s control.
            </div>
            <v-list
              v-if="toggleRoleMapping && (!editingConfig && identityConfigExists)"
              class="mx-8 pt-2">
              <v-table
                fixed-header
                dense>
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-center header-cell bainbridge-black--text">
                        Security Group
                      </th>
                      <th class="text-center header-cell bainbridge-black--text">
                        Role(s)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="mapping in localMappedRoles.slice(0, -1)"
                      :key="mapping.group">
                      <td>{{ mapping.group }}</td>
                      <td>{{ mapping.roles.join(', ') }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-table>
            </v-list>
            <v-list
              v-if="toggleRoleMapping && ((editingConfig && identityConfigExists) || !identityConfigExists)"
              class="mx-4 pt-0">
              <MappingRow
                v-for="(mapping, index) in localMappedRoles"
                :key="index"
                :index="index"
                :all-groups="mappingGroups"
                :allowed-groups="localAllowedGroups"
                :disallowed-groups="localDisallowedGroups"
                :group="mapping.group"
                :roles="mapping.roles"
                :disabled="(!editingConfig && identityConfigExists) || loading"
                @mappingChanged="updateLocalMapping"/>
            </v-list>
            <v-switch
              v-model="toggleSecurityGroups"
              :disabled="(!editingConfig && identityConfigExists) || loading"
              label="Specify Allowed Security Groups"
              color="primary"
              class="mb-4"
              hide-details/>
            <div
              :class="{
                'mb-2': toggleSecurityGroups,
                'mb-6': !toggleSecurityGroups,
              }"
              class="mx-4">
              This option should only be selected if you are not configuring allowed/disallowed security groups
              within your Active Directory instance. It is recommended to configure group access there so that
              user access is within your organization’s control.
            </div>
            <v-list
              v-if="toggleSecurityGroups && (!editingConfig && identityConfigExists)"
              class="mx-8 pt-2 pb-8">
              <div class="mb-2">
                <span class="bainbridge-black--text title-text">
                  Allowed Security Groups:
                </span>
                {{ localAllowedGroups.join(', ') }}
              </div>
              <div class="mb-2">
                <span class="bainbridge-black--text title-text">
                  Disallowed Security Groups:
                </span>
                {{ localDisallowedGroups.join(', ') }}
              </div>
            </v-list>
            <v-list
              v-if="toggleSecurityGroups && ((editingConfig && identityConfigExists) || !identityConfigExists)"
              class="mx-4 pb-8">
              <v-combobox
                v-model="localAllowedGroups"
                :rules="[
                  v => (
                    localAllowedGroups.length > 0 ? v.every(g => mappingGroups.includes(g)) : true
                  ) || 'Allowed groups do not match mapped groups',
                ]"
                :validate-on="true ? 'blur' : undefined"
                class="w-100"
                label="Allowed Security Groups"
                multiple
                chips
                variant="underlined"/>
              <v-combobox
                v-model="localDisallowedGroups"
                :rules="[
                  v => (
                    mappingGroups.length > 0 ? v.every(g => !mappingGroups.includes(g)) : true
                  ) || 'Disallowed groups included in mapped groups',
                ]"
                :validate-on="true ? 'blur' : undefined"
                class="w-100"
                label="Disallowed Security Groups"
                multiple
                chips
                variant="underlined"/>
            </v-list>
            <div class="d-flex">
              <v-btn
                v-if="identityConfigExists && !editingConfig"
                id="edit-button"
                :disabled="loading"
                class="text-white ml-0"
                color="primary"
                variant="flat"
                @click="editingConfig = true">
                Edit Configuration
              </v-btn>
              <v-btn
                v-if="editingConfig"
                id="cancel-button"
                :disabled="loading"
                class="text-white ml-0"
                color="primary"
                variant="flat"
                @click="resetConfig">
                Cancel
              </v-btn>
              <v-btn
                v-if="editingConfig || !identityConfigExists"
                id="save-button"
                :disabled="disabled || loading"
                class="text-white"
                color="secondary"
                variant="flat"
                @click="showConfirmationModal(MODAL_REASONS.SAVE_CONFIG)">
                Submit
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialog.show"
      max-width="500px">
      <v-card>
        <v-card-title class="text-h5 text-left">
          {{ dialog.title }}
        </v-card-title>
        <v-card-text
          v-if="dialog.text"
          class="text-left">
          {{ dialog.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            class="text-primary"
            variant="text"
            @click.stop="dismissModal()">
            Cancel
          </v-btn>
          <v-btn
            class="bg-secondary"
            variant="text"
            @click.stop="confirmModal()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';

import { arraysEqual } from '@/lib/compare';
import { EventLog } from '@/lib/event-log';
import { DEFAULT_MAPPING, USER_CONFIG_CAPABILITY } from '@/lib/identity-methods';
import { isUrl } from '@/lib/is-url';

import MappingRow from './MappingRow.vue';

const CERT_START = '-----BEGIN CERTIFICATE-----';
const CERT_END = '-----END CERTIFICATE-----';

export default {
  name: 'SsoConfig',
  components: {
    MappingRow,
  },
  data() {
    return {
      dialog: {
        show: false,
        title: null,
        text: null,
      },
      toggleRoleMapping: false,
      toggleSecurityGroups: false,
      valid: true,
      urlRules: [
        (v) => (v && isUrl(v.trim())) || 'Invalid URL. Ensure URL starts with https:// and is formatted correctly.',
      ],
      certRules: [
        (v) => (v && v.trim().startsWith(CERT_START) && v.trim().endsWith(CERT_END)) || (
          'Invalid certificate. Ensure certificate starts with -----BEGIN CERTIFICATE----- and ends with -----END CERTIFICATE-----.'
        ),
      ],
      localEntityId: null,
      localSsoUrl: null,
      localSamlCert: null,
      editingConfig: false,
      localMappedRoles: [DEFAULT_MAPPING],
      errors: [],
      localAllowedGroups: [],
      localDisallowedGroups: [],
      actionStatus: {
        createConfig: null,
        removeConfig: null,
        updateConfig: null,
        createRoleMapping: null,
        removeRoleMapping: null,
        updateRoleMapping: null,
        createSecurityGroups: null,
        removeSecurityGroups: null,
        updateSecurityGroups: null,
      },
      MODAL_REASONS: {
        DELETE_CONFIG: 'delete_config',
        SAVE_CONFIG: 'save_config',
      },
    };
  },
  computed: {
    dataLoading() {
      return !this.allowedRolesLoaded || !this.mappedRolesLoaded || !this.identityConfigLoaded ||
        !this.securityGroupsLoaded;
    },
    loading() {
      return this.dataLoading || (
        Object.keys(this.actionStatus).some((k) => this.actionStatus[k] === 'started')
      );
    },
    disabled() {
      return (
        (this.identityConfigExists && this.localEntityId.trim() === this.identityConfig.entityId &&
        this.localSsoUrl && this.localSsoUrl.trim() === this.identityConfig.ssoUrl &&
        this.localSamlCert && this.localSamlCert.trim() === this.identityConfig.samlCert &&
        arraysEqual(this.mappedRoles, this.localMappedRoles) &&
        (this.toggleRoleMapping === this.roleMappingExists || (
          !this.roleMappingExists && this.toggleRoleMapping && arraysEqual(this.localMappedRoles, [DEFAULT_MAPPING])
        )) && arraysEqual(this.allowedGroups, this.localAllowedGroups) &&
        arraysEqual(this.disallowedGroups, this.localDisallowedGroups) &&
        (this.toggleSecurityGroups === this.securityGroupsExist || (
          !this.securityGroupsExist && this.toggleSecurityGroups && this.localAllowedGroups.length === 0 &&
          this.localDisallowedGroups.length === 0
        ))) || !this.localEntityId || !this.localSsoUrl || !this.localSamlCert || !this.valid
      );
    },
    identityConfigExists() {
      return Boolean(this.identityConfig);
    },
    roleMappingExists() {
      return !arraysEqual(this.mappedRoles, [DEFAULT_MAPPING]);
    },
    securityGroupsExist() {
      return this.allowedGroups.length > 0 || this.disallowedGroups.length > 0;
    },
    mappingGroups() {
      return this.localMappedRoles.map((mapping) => mapping.group).filter((group) => group !== DEFAULT_MAPPING.group);
    },
    createComplete() {
      return (
        this.actionStatus.createConfig === 'complete' &&
        this.actionStatus.createRoleMapping === 'complete' &&
        this.actionStatus.createSecurityGroups === 'complete'
      );
    },
    updateComplete() {
      return (
        this.actionStatus.updateConfig === 'complete' &&
        this.actionStatus.updateRoleMapping === 'complete' &&
        this.actionStatus.updateSecurityGroups === 'complete'
      );
    },
    removeComplete() {
      return (
        this.actionStatus.removeConfig === 'complete' &&
        this.actionStatus.removeRoleMapping === 'complete' &&
        this.actionStatus.removeSecurityGroups === 'complete'
      );
    },
    ...mapGetters([
      'allowedGroups',
      'allowedRoles',
      'allowedRolesLoaded',
      'disallowedGroups',
      'identityConfig',
      'identityConfigLoaded',
      'mappedRoles',
      'mappedRolesLoaded',
      'securityGroupsLoaded',
      'menuNavigationStart',
      'tenant',
      'userHasCapability',
    ]),
  },
  watch: {
    dataLoading: {
      handler() {
        if (!this.dataLoading && this.menuNavigationStart && 'timestamp' in this.menuNavigationStart) {
          const now = moment();
          const menuData = {
            timestamp: now,
            menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
          };
          this.setMenuNavigationEnd(menuData);
        }
      },
    },
    identityConfigLoaded: {
      handler() {
        if (this.identityConfigLoaded) {
          if (this.identityConfigExists) {
            this.localEntityId = cloneDeep(this.identityConfig.entityId);
            this.localSsoUrl = cloneDeep(this.identityConfig.ssoUrl);
            this.localSamlCert = cloneDeep(this.identityConfig.samlCert);
          } else {
            this.localEntityId = null;
            this.localSsoUrl = null;
            this.localSamlCert = null;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    mappedRolesLoaded: {
      handler() {
        if (this.mappedRolesLoaded) {
          if (this.roleMappingExists) {
            this.toggleRoleMapping = true;
          }
          this.localMappedRoles = cloneDeep(this.mappedRoles);
        }
      },
      deep: true,
      immediate: true,
    },
    securityGroupsLoaded: {
      handler() {
        if (this.securityGroupsLoaded) {
          if (this.securityGroupsExist) {
            this.toggleSecurityGroups = true;
          }
          this.localAllowedGroups = cloneDeep(this.allowedGroups);
          this.localDisallowedGroups = cloneDeep(this.disallowedGroups);
        }
      },
      deep: true,
      immediate: true,
    },
    createComplete: {
      handler() {
        if (this.createComplete) {
          if (this.errors.length > 0) {
            this.$notify(`Failed to save your SSO configuration because of the following error(s): ${this.errors.join(', ')}`);
            this.errors = [];
          } else {
            this.$notify('Your SSO configuration has been saved.');
          }
          const loggingData = new EventLog({
            event: 'saml.create_config',
          });
          this.$services.users.postTrackingLog(loggingData);
          this.actionStatus.createConfig = null;
          this.actionStatus.createRoleMapping = null;
          this.actionStatus.createSecurityGroups = null;
          this.actionStatus.updateRoleMapping = null;
          this.actionStatus.updateSecurityGroups = null;
        }
      },
      deep: true,
    },
    updateComplete: {
      handler() {
        if (this.updateComplete) {
          if (this.errors.length > 0) {
            this.$notify(`Failed to save your SSO configuration because of the following error(s): ${this.errors.join(', ')}`);
            this.errors = [];
          } else {
            this.$notify('Your SSO configuration has been saved.');
          }
          const loggingData = new EventLog({
            event: 'saml.update_config',
          });
          this.$services.users.postTrackingLog(loggingData);
          this.actionStatus.updateConfig = null;
          this.actionStatus.updateRoleMapping = null;
          this.actionStatus.updateSecurityGroups = null;
          this.actionStatus.createRoleMapping = null;
          this.actionStatus.createSecurityGroups = null;
        }
      },
      deep: true,
    },
    removeComplete: {
      handler() {
        if (this.removeComplete) {
          if (this.errors.length > 0) {
            this.$notify(`Failed to remove your SSO configuration because of the following error(s): ${this.errors.join(', ')}`);
            this.errors = [];
          } else {
            this.$notify('Your SSO configuration has been removed. Your organization\'s users will now use password login ' +
              'and must reset their passwords.');
          }
          const loggingData = new EventLog({
            event: 'saml.remove_config',
          });
          this.$services.users.postTrackingLog(loggingData);
          this.actionStatus.removeConfig = null;
          this.actionStatus.removeRoleMapping = null;
          this.actionStatus.removeSecurityGroups = null;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.reloadConfig();
    if (!this.dataLoading) {
      const now = moment();
      const menuData = {
        timestamp: now,
        menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
      };
      this.setMenuNavigationEnd(menuData);
    }
  },
  methods: {
    resetConfig() {
      if (this.identityConfigExists) {
        this.localEntityId = cloneDeep(this.identityConfig.entityId);
        this.localSsoUrl = cloneDeep(this.identityConfig.ssoUrl);
        this.localSamlCert = cloneDeep(this.identityConfig.samlCert);
      } else {
        this.localEntityId = null;
        this.localSsoUrl = null;
        this.localSamlCert = null;
      }
      if (this.roleMappingExists) {
        this.localMappedRoles = cloneDeep(this.mappedRoles);
        this.toggleRoleMapping = true;
      } else {
        this.localMappedRoles = [DEFAULT_MAPPING];
        this.toggleRoleMapping = false;
      }
      if (this.securityGroupsExist) {
        this.localAllowedGroups = cloneDeep(this.allowedGroups);
        this.localDisallowedGroups = cloneDeep(this.disallowedGroups);
        this.toggleSecurityGroups = true;
      } else {
        this.localAllowedGroups = [];
        this.localDisallowedGroups = [];
        this.toggleSecurityGroups = false;
      }
      this.$refs.ssoForm.validate();
      this.editingConfig = false;
    },
    reloadConfig() {
      this.localEntityId = null;
      this.localSsoUrl = null;
      this.localSamlCert = null;
      this.setIdentityConfig();
      this.editingConfig = false;
    },
    reloadMappedRoles() {
      this.localMappedRoles = [DEFAULT_MAPPING];
      this.toggleRoleMapping = false;
      this.setMappedRoles();
      this.editingConfig = false;
    },
    reloadSecurityGroups() {
      this.localAllowedGroups = [];
      this.localDisallowedGroups = [];
      this.toggleSecurityGroups = false;
      this.setSecurityGroups();
      this.editingConfig = false;
    },
    updateLocalMapping(updates) {
      if (arraysEqual(updates.roles, DEFAULT_MAPPING.roles) &&
        updates.group === DEFAULT_MAPPING.group &&
        this.localMappedRoles.length > 0) {
        this.localMappedRoles = this.localMappedRoles.slice(updates.index);
      } else {
        const changes = {
          group: updates.group,
          roles: updates.roles,
        };
        this.localMappedRoles[updates.index] = changes;
      }
      const defaultIndex = this.localMappedRoles.findIndex(
        (m) => m.group === DEFAULT_MAPPING.group && arraysEqual(m.roles, DEFAULT_MAPPING.roles),
      );
      if (defaultIndex === -1) {
        this.localMappedRoles.push(DEFAULT_MAPPING);
      }
    },
    showConfirmationModal(reason) {
      this.$refs.ssoForm.validate();
      if (this.valid) {
        this.dialog.reason = reason;
        if (reason === this.MODAL_REASONS.SAVE_CONFIG) {
          this.dialog.title = 'Are you sure you want to save your SSO configuration?';
          if (this.identityConfigExists) {
            this.dialog.text = 'Your previous configuration will be overwritten.';
          }
          this.dialog.show = true;
        } else if (reason === this.MODAL_REASONS.DELETE_CONFIG) {
          this.dialog.title = 'Are you sure you want to remove your SSO configuration?';
          this.dialog.text = 'Existing SSO users will be switched to password login and must reset their passwords.';
          this.dialog.show = true;
        }
      }
    },
    createConfig() {
      this.actionStatus.createConfig = 'started';
      const createData = {
        entity_id: this.localEntityId.trim(),
        sso_url: this.localSsoUrl.trim(),
        saml_cert: this.localSamlCert.trim(),
      };
      this.$services.identity.postTenantProvider(this.tenant.id, createData).then((result) => {
        this.reloadConfig();
      }).catch((error) => {
        this.errors.push(error.message);
        const failLog = new EventLog({
          event: 'saml.fail_create_config',
          error: error.message,
        });
        this.$services.users.postTrackingLog(failLog);
        Sentry.withScope((scope) => {
          Sentry.setContext('action_attributes', {
            module: 'sso_admin',
            action: 'saml.fail_create_config',
            entity_id: this.localEntityId.trim(),
            sso_url: this.localSsoUrl.trim(),
            saml_cert: this.localSamlCert.trim(),
          });
          scope.setLevel('warning');
          Sentry.captureException(new Error(error));
        });
      }).finally(() => {
        this.actionStatus.createConfig = 'complete';
      });
    },
    updateConfig() {
      this.actionStatus.updateConfig = 'started';
      const updateData = {
        entity_id: this.localEntityId,
        sso_url: this.localSsoUrl,
        saml_cert: this.localSamlCert,
      };
      this.$services.identity.putTenantProvider(this.tenant.id, updateData).then((result) => {
        this.reloadConfig();
      }).catch((error) => {
        this.errors.push(error.message);
        const failLog = new EventLog({
          event: 'saml.fail_update_config',
          error: error.message,
        });
        this.$services.users.postTrackingLog(failLog);
        Sentry.withScope((scope) => {
          Sentry.setContext('action_attributes', {
            module: 'sso_admin',
            action: 'saml.fail_update_config',
            entity_id: this.localEntityId.trim(),
            sso_url: this.localSsoUrl.trim(),
            saml_cert: this.localSamlCert.trim(),
          });
          scope.setLevel('warning');
          Sentry.captureException(new Error(error));
        });
      }).finally(() => {
        this.actionStatus.updateConfig = 'complete';
      });
    },
    deleteConfig() {
      this.actionStatus.removeConfig = 'started';
      this.$services.identity.deleteTenantProvider(this.tenant.id).then((result) => {
        this.reloadConfig();
        if (this.userHasCapability(USER_CONFIG_CAPABILITY)) {
          this.setTenantUsers();
        }
      }).catch((error) => {
        this.errors.push(error.message);
        const failLog = new EventLog({
          event: 'saml.fail_remove_config',
          error: error.message,
        });
        this.$services.users.postTrackingLog(failLog);
        Sentry.withScope((scope) => {
          Sentry.setContext('action_attributes', {
            module: 'sso_admin',
            action: 'saml.fail_remove_config',
            entity_id: this.localEntityId.trim(),
            sso_url: this.localSsoUrl.trim(),
            saml_cert: this.localSamlCert.trim(),
          });
          scope.setLevel('warning');
          Sentry.captureException(new Error(error));
        });
      }).finally(() => {
        this.actionStatus.removeConfig = 'complete';
      });
    },
    createRoleMapping() {
      this.actionStatus.createRoleMapping = 'started';
      const createData = cloneDeep(this.localMappedRoles);
      const defaultIndex = createData.findIndex(
        (m) => m.group === DEFAULT_MAPPING.group && arraysEqual(m.roles, DEFAULT_MAPPING.roles),
      );
      if (defaultIndex !== -1) {
        createData.splice(defaultIndex, 1);
      }
      createData.forEach((mapping) => {
        mapping.roles = mapping.roles.map((r) => this.allowedRoles.find((role) => role.display_name === r).id);
      });
      this.$services.users.postTenantRoleMapping(this.tenant.id, createData).then((result) => {
        this.reloadMappedRoles();
        const loggingData = new EventLog({
          event: 'saml.create_role_mapping',
        });
        this.$services.users.postTrackingLog(loggingData);
      }).catch((error) => {
        this.errors.push(error.message);
        const failLog = new EventLog({
          event: 'saml.fail_create_role_mapping',
          error: error.message,
        });
        this.$services.users.postTrackingLog(failLog);
        Sentry.withScope((scope) => {
          Sentry.setContext('action_attributes', {
            module: 'sso_admin',
            action: 'saml.fail_create_role_mapping',
            mappedRoles: this.localMappedRoles,
          });
          scope.setLevel('warning');
          Sentry.captureException(new Error(error));
        });
      }).finally(() => {
        this.actionStatus.createRoleMapping = 'complete';
      });
    },
    updateRoleMapping() {
      this.actionStatus.updateRoleMapping = 'started';
      const updateData = this.localMappedRoles;
      const defaultIndex = updateData.findIndex(
        (m) => m.group === DEFAULT_MAPPING.group && arraysEqual(m.roles, DEFAULT_MAPPING.roles),
      );
      if (defaultIndex !== -1) {
        updateData.splice(defaultIndex, 1);
      }
      updateData.forEach((mapping) => {
        mapping.roles = mapping.roles.map((r) => this.allowedRoles.find((role) => role.display_name === r).id);
      });
      this.$services.users.putTenantRoleMapping(this.tenant.id, updateData).then((result) => {
        this.reloadMappedRoles();
        const loggingData = new EventLog({
          event: 'saml.update_role_mapping',
        });
        this.$services.users.postTrackingLog(loggingData);
      }).catch((error) => {
        this.errors.push(error.message);
        const failLog = new EventLog({
          event: 'saml.fail_update_role_mapping',
          error: error.message,
        });
        this.$services.users.postTrackingLog(failLog);
        Sentry.withScope((scope) => {
          Sentry.setContext('action_attributes', {
            module: 'sso_admin',
            action: 'saml.fail_update_role_mapping',
            mappedRoles: this.localMappedRoles,
          });
          scope.setLevel('warning');
          Sentry.captureException(new Error(error));
        });
      }).finally(() => {
        this.actionStatus.updateRoleMapping = 'complete';
      });
    },
    deleteRoleMapping() {
      this.actionStatus.deleteRoleMapping = 'started';
      this.$services.users.deleteTenantRoleMapping(this.tenant.id).then((result) => {
        this.reloadMappedRoles();
        const loggingData = new EventLog({
          event: 'saml.remove_role_mapping',
        });
        this.$services.users.postTrackingLog(loggingData);
      }).catch((error) => {
        this.errors.push(error.message);
        const failLog = new EventLog({
          event: 'saml.fail_remove_role_mapping',
          error: error.message,
        });
        this.$services.users.postTrackingLog(failLog);
        Sentry.withScope((scope) => {
          Sentry.setContext('action_attributes', {
            module: 'sso_admin',
            action: 'saml.fail_remove_role_mapping',
            mappedRoles: this.localMappedRoles,
          });
          scope.setLevel('warning');
          Sentry.captureException(new Error(error));
        });
      }).finally(() => {
        this.actionStatus.deleteRoleMapping = 'complete';
      });
    },
    createSecurityGroups() {
      this.actionStatus.createSecurityGroups = 'started';
      const createData = {
        allowed_groups: this.localAllowedGroups,
        disallowed_groups: this.localDisallowedGroups,
      };
      this.$services.users.postTenantSecurityGroups(this.tenant.id, createData).then((result) => {
        this.reloadSecurityGroups();
        const loggingData = new EventLog({
          event: 'saml.create_security_groups',
        });
        this.$services.users.postTrackingLog(loggingData);
      }).catch((error) => {
        this.errors.push(error.message);
        const failLog = new EventLog({
          event: 'saml.fail_create_security_groups',
          error: error.message,
        });
        this.$services.users.postTrackingLog(failLog);
        Sentry.withScope((scope) => {
          Sentry.setContext('action_attributes', {
            module: 'sso_admin',
            action: 'saml.fail_create_security_groups',
            allowedGroups: this.localAllowedGroups,
            disallowedGroups: this.localDisallowedGroups,
          });
          scope.setLevel('warning');
          Sentry.captureException(new Error(error));
        });
      }).finally(() => {
        this.actionStatus.createSecurityGroups = 'complete';
      });
    },
    updateSecurityGroups() {
      this.actionStatus.updateSecurityGroups = 'started';
      const updateData = {
        allowed_groups: this.localAllowedGroups,
        disallowed_groups: this.localDisallowedGroups,
      };
      this.$services.users.putTenantSecurityGroups(this.tenant.id, updateData).then((result) => {
        this.reloadSecurityGroups();
        const loggingData = new EventLog({
          event: 'saml.update_security_groups',
        });
        this.$services.users.postTrackingLog(loggingData);
      }).catch((error) => {
        this.errors.push(error.message);
        const failLog = new EventLog({
          event: 'saml.fail_update_security_groups',
          error: error.message,
        });
        this.$services.users.postTrackingLog(failLog);
        Sentry.withScope((scope) => {
          Sentry.setContext('action_attributes', {
            module: 'sso_admin',
            action: 'saml.fail_update_security_groups',
            allowedGroups: this.localAllowedGroups,
            disallowedGroups: this.localDisallowedGroups,
          });
          scope.setLevel('warning');
          Sentry.captureException(new Error(error));
        });
      }).finally(() => {
        this.actionStatus.updateSecurityGroups = 'complete';
      });
    },
    deleteSecurityGroups() {
      this.actionStatus.deleteSecurityGroups = 'started';
      this.$services.users.deleteTenantSecurityGroups(this.tenant.id).then((result) => {
        this.reloadSecurityGroups();
        const loggingData = new EventLog({
          event: 'saml.remove_security_groups',
        });
        this.$services.users.postTrackingLog(loggingData);
      }).catch((error) => {
        this.errors.push(error.message);
        const failLog = new EventLog({
          event: 'saml.fail_remove_security_groups',
          error: error.message,
        });
        this.$services.users.postTrackingLog(failLog);
        Sentry.withScope((scope) => {
          Sentry.setContext('action_attributes', {
            module: 'sso_admin',
            action: 'saml.fail_remove_security_groups',
            allowedGroups: this.localAllowedGroups,
            disallowedGroups: this.localDisallowedGroups,
          });
          scope.setLevel('warning');
          Sentry.captureException(new Error(error));
        });
      }).finally(() => {
        this.actionStatus.deleteSecurityGroups = 'complete';
      });
    },
    confirmModal() {
      this.dialog.show = false;
      if (this.dialog.reason === this.MODAL_REASONS.SAVE_CONFIG) {
        if (this.identityConfigExists) {
          this.updateConfig();
        } else {
          this.createConfig();
        }
        if (this.roleMappingExists) {
          if (!this.toggleRoleMapping) {
            this.deleteRoleMapping();
          } else if (!arraysEqual(this.localMappedRoles, this.mappedRoles)) {
            this.updateRoleMapping();
          }
        } else if (this.localMappedRoles.length > 1) {
          this.createRoleMapping();
        } else {
          this.toggleRoleMapping = false;
          this.actionStatus.createRoleMapping = 'complete';
          this.actionStatus.updateRoleMapping = 'complete';
        }
        if (this.securityGroupsExist) {
          if (!this.toggleSecurityGroups) {
            this.deleteSecurityGroups();
          } else if (!arraysEqual(this.localAllowedGroups, this.allowedGroups) ||
            !arraysEqual(this.localDisallowedGroups, this.disallowedGroups)) {
            this.updateSecurityGroups();
          }
        } else if (this.localAllowedGroups.length > 0 || this.localDisallowedGroups.length > 0) {
          this.createSecurityGroups();
        } else {
          this.toggleSecurityGroups = false;
          this.actionStatus.createSecurityGroups = 'complete';
          this.actionStatus.updateSecurityGroups = 'complete';
        }
      } else if (this.dialog.reason === this.MODAL_REASONS.DELETE_CONFIG) {
        this.deleteConfig();
        if (this.roleMappingExists) {
          this.deleteRoleMapping();
        } else {
          this.actionStatus.removeRoleMapping = 'complete';
        }
        if (this.securityGroupsExist) {
          this.deleteSecurityGroups();
        } else {
          this.actionStatus.removeSecurityGroups = 'complete';
        }
      }
      this.dismissModal();
    },
    dismissModal() {
      this.dialog.title = null;
      this.dialog.text = null;
      this.dialog.reason = null;
      this.dialog.show = false;
    },
    ...mapActions([
      'setIdentityConfig',
      'setMappedRoles',
      'setMenuNavigationEnd',
      'setSecurityGroups',
      'setTenantUsers',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.sso-config {
  width: 65%;
  padding-bottom: 3rem;
}
.headline {
  word-break: break-word;
}
.header-cell {
  font-size: 14px;
}
.title-text {
  font-weight: bold;
}
</style>
