<template>
  <v-container class="pt-12">
    <div
      class="user-testing-container"
      lg8
      md10
      xs12>
      <v-card class="text-left">
        <v-card-title class="bg-primary">
          <span class="text-white">
            User Troubleshooting
          </span>
        </v-card-title>
        <v-card-text
          v-if="!usersLoaded"
          class="mt-2 text-center">
          <v-progress-circular
            indeterminate
            class="text-primary"/>
        </v-card-text>
        <v-card-text v-else>
          <div
            class="mt-4">
            <span v-if="!usersExist">
              There are no active users to utilize this feature for.
            </span>
            <span v-else>
              Select a user and generate a unique tracking ID to troubleshoot his/her workflow through the portal.
            </span>
            <div
              v-if="usersExist"
              class="w-100 pt-4 d-flex">
              <v-form
                ref="userForm"
                v-model="valid"
                class="flex-1"
                lazy-validation>
                <v-combobox
                  v-model="userEmail"
                  v-model:search="userQuery"
                  :disabled="actionStatus.generatingID"
                  :items="sortedUsers"
                  :validate-on="true ? 'blur' : undefined"
                  :rules="[
                    v => !!v && v.length > 0 || 'Required',
                    v => !!v && sortedUsers.includes(v.toLowerCase()) || 'Invalid selection',
                  ]"
                  class="mt-2"
                  label="User"
                  variant="underlined"
                  @update:model-value="userQuery = ''"/>
              </v-form>
              <v-btn
                :disabled="actionStatus.generatingID"
                :loading="actionStatus.generatingID"
                class="bg-secondary ml-4"
                variant="text"
                @click.stop="generateId()">
                Generate ID
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-text
          v-if="trackToken && !actionStatus.generatingID"
          class="pt-0">
          <div class="pb-2">
            Send the track URL below to the user to begin logging his/her workflow with the generated track ID.
            Instruct the user to log out, then log back in using this URL.
            Click the link next to the track ID to view the tracking logs for the user once they begin their workflow.
            Note that the URL and ID expire in 24 hours.
          </div>
          <v-list text>
            <v-list-item lines="two">
              <v-list-item-title>
                Track ID
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <v-btn
                      v-bind="props"
                      :href="dashboardLink"
                      target="_blank"
                      icon
                      variant="text">
                      <v-icon icon="mdi-open-in-new"/>
                    </v-btn>
                  </template>
                  <span>View user tracking logs</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ trackId }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>
                Track URL
                <v-tooltip
                  v-if="linkCopied"
                  v-model="linkCopied"
                  color="secondary"
                  location="top">
                  <template #activator="{ props }">
                    <v-btn
                      v-bind="props"
                      :data-url="trackLink"
                      icon
                      variant="text"
                      class="mr-0"
                      @click="copyLink">
                      <v-icon icon="mdi-content-copy"/>
                    </v-btn>
                  </template>
                  <span>Link Copied!</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  location="bottom">
                  <template #activator="{ props }">
                    <v-btn
                      v-bind="props"
                      :data-url="trackLink"
                      icon
                      variant="text"
                      class="mr-0"
                      @click="copyLink">
                      <v-icon icon="mdi-content-copy"/>
                    </v-btn>
                  </template>
                  <span>Copy link</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ trackLink }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import { EventLog } from '@/lib/event-log';
import { IS_EQUAL_TO } from '@/lib/constants';
import { EMPLOYEE_TENANT_NAME } from '@/lib/identity-methods';

const BASE_URL = window.location.origin;

export default {
  name: 'UserTesting',
  data() {
    return {
      valid: false,
      userEmail: '',
      userQuery: '',
      actionStatus: {
        generatingID: false,
      },
      trackToken: '',
      trackId: '',
      filterSetId: '',
      linkCopied: false,
    };
  },
  computed: {
    loading() {
      return !this.usersLoaded || Object.keys(this.actionStatus).some((k) => this.actionStatus[k] === true);
    },
    usersExist() {
      return this.users.length > 0;
    },
    sortedUsers() {
      const users = cloneDeep(this.users);
      users.sort((x, y) => ((x.email.toLowerCase() > y.email.toLowerCase()) ? 1 : -1));
      const results = users.map((u) => u.email.toLowerCase());
      if (this.userQuery) {
        results.sort((x, y) => {
          if (x.toLowerCase().startsWith(this.userQuery.toLowerCase())) {
            return -1;
          } else if (y.toLowerCase().startsWith(this.userQuery.toLowerCase())) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return results;
    },
    trackLink() {
      return `${BASE_URL}/?track=${this.trackToken}`;
    },
    dashboardLink() {
      return `${BASE_URL}/?filter_set=${this.filterSetId}&tenant=${EMPLOYEE_TENANT_NAME}#/analytics`;
    },
    ...mapGetters([
      'users',
      'usersLoaded',
    ]),
  },
  watch: {
    usersLoaded: {
      handler() {
        this.menuNavigationTrack()
      },
    },
  },
  mounted() {
    if (!this.usersLoaded) {
      this.setUsers()
    } else {
      this.menuNavigationTrack()
    }
  },
  methods: {
    menuNavigationTrack() {
      const now = moment();
      const menuData = {
        timestamp: now,
        menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
      };
      this.setMenuNavigationEnd(menuData);
    },
    generateId() {
      this.$refs.userForm.validate(true);
      if (this.valid) {
        this.actionStatus.generatingID = true;
        this.trackToken = '';
        this.trackId = '';
        this.filterSetId = '';
        const user = this.users.find((u) => u.email.toLowerCase() === this.userEmail.toLowerCase());
        this.$services.users.getTrackToken(user.id).then((result) => {
          const filterSet = {
            tracking_id: {
              value: result.track_id,
              option: IS_EQUAL_TO,
            },
            tracking_user_type: {
              value: null,
              option: IS_EQUAL_TO,
            },
          };
          this.$services.filters.postFilterSet(filterSet).then((response) => {
            this.trackToken = result.track_token;
            this.trackId = result.track_id;
            this.filterSetId = response.success;
            const loggingData = new EventLog({
              event: 'debug.generate_track_token',
              trackedUser: this.userEmail,
            });
            this.$services.users.postTrackingLog(loggingData);
          }).catch((error) => {
            const failLog = new EventLog({
              event: 'debug.fail_generate_track_token',
              trackedUser: this.userEmail,
              error: error.message,
            });
            this.$services.users.postTrackingLog(failLog);
            this.$notify(error);
          });
        }).catch((error) => {
          const failLog = new EventLog({
            event: 'debug.fail_generate_track_token',
            trackedUser: this.userEmail,
            error: error.message,
          });
          this.$services.users.postTrackingLog(failLog);
          this.$notify(error);
        }).finally(() => {
          this.actionStatus.generatingID = false;
        });
      }
    },
    copyLink() {
      if (!this.linkCopied) {
        const link = document.createElement('textarea');
        link.style.position = 'absolute';
        link.style.top = `${window.pageYOffset || document.documentElement.scrollTop}px`;
        link.value = this.trackLink;
        document.body.appendChild(link);
        link.select();
        const copied = document.execCommand('copy');
        if (copied) {
          this.linkCopied = true;
        }
        document.body.removeChild(link);
      }
    },
    ...mapActions([
      'setUsers',
      'setMenuNavigationEnd',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.user-testing-container {
  margin: auto;
  max-width: 800px;
}
.headline {
  word-break: break-word;
}
.expire-message {
  font-weight: bold;
}
.link-text {
  text-decoration: underline;
  cursor: pointer;
}
</style>
